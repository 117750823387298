import { defineStore } from 'pinia';

interface adminTokenState {
  token: String | null,
}

export const useTokenStore = defineStore('adminToken', {
  state: (): adminTokenState => ({
    token: null,
  }),
});
