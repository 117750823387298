import type { Ref } from '@nuxtjs/composition-api';
import { ref, readonly, useContext } from '@nuxtjs/composition-api';
import type {
  UseUpsellProductsError,
  UseUpsellProductsInterface,
} from './useUpsellProducts';
import { useApi } from '~/composables';
import getUpsellProductDetailsQuery from '~/customQueries/getUpsellProductDetailsQuery';

/**
 * The `useUpsellProducts()` composable allows loading upsell products.
 *
 * See the {@link UseUpsellProductsInterface} page for more information.
 */
export function useUpsellProducts(): UseUpsellProductsInterface {
  const { app } = useContext();
  const loading = ref(false);
  const { query } = useApi();
  const error: Ref<UseUpsellProductsError> = ref({
    search: null,
  });

  const search = async (searchParams) => {
    let products;
    let results = null;
    try {
      loading.value = true;

      const { data } = await app
        .$vsf
        .$magento
        .api
        .upsellProduct(searchParams, { upsellProducts: 'upsellProducts' });

      results = data.products?.items[0]?.upsell_products;
      error.value.search = null;
    } catch (err) {
      throw new Error(err);
    } finally {
      loading.value = false;
    }
    return results;
  };

  return {
    search,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useUpsellProducts;
export * from './useUpsellProducts';