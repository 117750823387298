import {
  ref,
  readonly,
  computed,
  useContext,
  useRouter,
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useCart } from '~/modules/checkout/composables/useCart';
import { generateUserData } from '~/modules/customer/helpers/generateUserData';
import { Customer } from '~/modules/GraphQL/types';
import customerQuery from './customer.gql';
import { useApi } from '~/composables/useApi';
import createPaypalExpressTokenGql from '~/composables/usePaypal/createPaypalExpressToken.gql';
import customerGql from './customer.gql';
import type {
  UseUserInterface,
  UseUserErrors,
  UseUserLoadParams,
  UseUserLoginParams,
  UseUserLogoutParams,
  UseUserRegisterParams,
  UseUserUpdateUserParams,
  UseUserChangePasswordParams,
} from './useUser';
import { LastLogin } from '~/repository/putLastLogin.repository';
import { CustomerEmail } from '~/repository/getCustomer.repository';
import { useCartStore } from "~/modules/checkout/stores/cart";


/**
 * Allows loading and manipulating data of the current user.
 *
 * See the {@link UseUserInterface} for a list of methods and values available in this composable.
 */
export function useUser(): UseUserInterface {
  const customerStore = useCustomerStore();
  const { app } = useContext();
  const { setCart } = useCart();
  const loading: Ref<boolean> = ref(false);
  const cartStore = useCartStore();

  const errorsFactory = () : UseUserErrors => ({
    updateUser: null,
    register: null,
    login: null,
    logout: null,
    changePassword: null,
    load: null,
  });
  const error: Ref = ref(errorsFactory());
  const router = useRouter();

  const setUser = (newUser: Customer) => {
    customerStore.user = newUser;
  };

  const resetErrorValue = () => {
    error.value = errorsFactory();
  };

  const updateCustomerEmail = async (credentials: { email: string, password: string }): Promise<void> => {
    const { errors } = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);

    if (errors) {
      throw errors.map((e) => e.message).join(',');
    }
  };

  // eslint-disable-next-line consistent-return
  const updateUser = async ({ user: providedUser, customQuery }: UseUserUpdateUserParams) => {
    resetErrorValue();

    try {
      loading.value = true;
      const { email: oldEmail } = customerStore.user;
      const { email, password, ...updateData } = providedUser;

      const userData = generateUserData(updateData);

      if (email && email !== oldEmail) {
        await updateCustomerEmail({
          email,
          password,
        });
      }

      const { data, errors } = await app.context.$vsf.$magento.api.updateCustomer(userData);

      if (errors) {
        const allErrorMessages = errors.map((e) => e.message).join(',');
        error.value.updateUser = allErrorMessages;
      }

      customerStore.user = data?.updateCustomerV2?.customer || {};
      error.value.updateUser = null;
    } catch (err) {
      error.value.updateUser = err;
    } finally {
      loading.value = false;
    }
  };

  const logout = async ({ customQuery = {} }: UseUserLogoutParams = {}) => {
    resetErrorValue();

    try {
      const apiState = app.context.$vsf.$magento.config.state;

      await app.context.$vsf.$magento.api.revokeCustomerToken({ customQuery });

      apiState.removeCustomerToken();
      apiState.removeCartId();
      setCart(null);
      customerStore.setIsLoggedIn(false);
      error.value.logout = null;
      customerStore.user = null;
    } catch (err) {
      error.value.logout = err;
    }
  };

  const load = async ({ customQuery = {} }: UseUserLoadParams = {}) => {
    resetErrorValue();

    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;
      const { query } = useApi();

      if (!apiState.getCustomerToken()) {
        return null;
      }
      try {
        if(customerStore.user === null) {
          const data = await query(customerGql, customQuery, {})
          customerStore.user = data?.customer ?? {};
        }

      } catch {
        await logout();
      }
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
    } finally {
      loading.value = false;
    }

    return customerStore.user;
  };

  const login = async ({ user: providedUser, customQuery }: UseUserLoginParams) : Promise<void> => {
    
    resetErrorValue();
    
    try {
      loading.value = true;
      const apiState = app.context.$vsf.$magento.config.state;
  
      let anonymousCartItems = cartStore.cart?.items || [];
  
      const { data, errors } = await app.context.$vsf.$magento.api.generateCustomerToken({
        email: providedUser.email,
        password: providedUser.password,
        recaptchaToken: providedUser.recaptchaToken,
      }, customQuery || {});
  
      if (errors) {
        console.error("Erreur de connexion :", errors.map((e) => e.message).join(','));
        error.value.login = { message: errors.map((e) => e.message).join(',') };
        return;
      }
  
      await load();
      customerStore.setIsLoggedIn(true);
      apiState.setCustomerToken(data.generateCustomerToken.token);
  
      const cart = await app.context.$vsf.$magento.api.customerCart();
      const newCartId = cart.data.customerCart?.id;
  
      if (anonymousCartItems.length > 0) {

        for (const item of anonymousCartItems) {
  
          await app.context.$vsf.$magento.api.addSimpleProductsToCart({
            cart_id: newCartId,
            cart_items: [
              {
                data: {
                  quantity: item.quantity,
                  sku: item.product.sku,
                },
              },
            ],
          });
          
        }
      }
  
      const updatedCart = await app.context.$vsf.$magento.api.customerCart();
      setCart(updatedCart.data.customerCart);
      cartStore.cart = updatedCart.data.customerCart;
  
      error.value.login = null;

    } catch (err) {
      console.error("Erreur dans le processus de connexion :", err);
      error.value.login = err;

    } finally {
      loading.value = false;
    }
  };
  
  // eslint-disable-next-line consistent-return
  const register = async ({ user: providedUser, customQuery }: UseUserRegisterParams) : Promise<void> => {
    resetErrorValue();

    try {
      loading.value = true;

      const {
        email,
        password,
        recaptchaToken,
        ...baseData
      } = generateUserData(providedUser);

      const { data, errors } = await app.context.$vsf.$magento.api.createCustomer(
        {
          email,
          password,
          recaptchaToken,
          ...baseData,
        },
        customQuery || {},
      );
      if (errors) {
        error.value.register = errors.map(e => e.message).join(', ');
        return
      }

      await login({ user: { email, password }, customQuery: {} });
      
      if (window.location.pathname.includes('inscription')) {
        router.push(app.localePath('/'));
      }
    } catch (err) {
      error.value.register = err.message || 'An unexpected error occurred';
    } finally {
      loading.value = false;
    }
  };

  // eslint-disable-next-line consistent-return
  const changePassword = async (params: UseUserChangePasswordParams) => {
    resetErrorValue();

    try {
      loading.value = true;

      const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword({
        currentUser: customerStore.user,
        currentPassword: params.current,
        newPassword: params.new,
        customQuery: params.customQuery,
      });

      let joinedErrors = null;

      if (errors) {
        joinedErrors = errors.map((e) => e.message).join(',');
      }


      customerStore.user = data?.changeCustomerPassword;
      error.value.changePassword = joinedErrors;
    } catch (err) {
      error.value.changePassword = err;
    } finally {
      loading.value = false;
    }
  };

  return {
    setUser,
    updateUser,
    register,
    login,
    logout,
    changePassword,
    load,
    loading: readonly(loading),
    error: readonly(error),
    user: computed(() => customerStore.user),
    isAuthenticated: computed(() => customerStore.isLoggedIn),
  };
}

export default useUser;
export * from './useUser';