import { useApi } from '~/composables/useApi';
import { AttributesService } from '../../../repository/attributes.repository';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { removeAccents } from '../../../helpers/removeAccents';

export function useProductCard() {
  const { query } = useApi();
  const { getProductDetails } = useProduct();

  const getGalleryByColor = async (color: string, product, mediaGalleryConfigurable, filter): Promise<[][]> => {
    const colorConfiguration = product?.find((option) => option.attributes[1]?.uid === color)?.attributes[1]?.label;
    const productConfiguration = product?.find((variant) => {
      return variant.attributes.some((attribute) => attribute.label === colorConfiguration);
    });

    let mediaGallery = productConfiguration?.product?.media_gallery || mediaGalleryConfigurable;
    const hasVideo = mediaGalleryConfigurable?.find((picture) => picture.__typename === 'ProductVideo');

    if (hasVideo) {
      const extraParams = "?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&muted=1&controls=0&loop=1";  
      let videoId;
  
      if (hasVideo.video_content.video_url.includes('?share')) {
        const videoInfoArray = hasVideo.video_content.video_url.split("/");
        videoId = videoInfoArray[3].split('?')[0];
      } else {
        videoId = hasVideo.video_content.video_url.split("/")[3];
      }
  
      if (!hasVideo.video_content.video_url.includes("player.vimeo.com")) {
        hasVideo.video_content.video_url = `https://player.vimeo.com/video/${videoId}${extraParams}`;
      }
  
      mediaGallery = [mediaGallery[0], hasVideo, ...mediaGallery.filter(item => item.__typename !== 'ProductVideo')];
    }

    if (!mediaGallery?.length) {
      const notPicture = {
        disabled: false,
        label: '',
        position: 0,
        url: `${process.env.VSF_MAGENTO_BASE_URL}/static/frontend/Magento/luma/fr_FR/Magento_Catalog/images/product/placeholder/image.jpg`
      };
      mediaGallery = [notPicture, notPicture];
    }

    return mediaGallery;
  };   

  const getColorConfiguration = async (configurableOptions, filter: string | null, productVariant): Promise<[][]> => {
    let configuration = [];
    let attribute;
    let uid = [];
    let colorSelectedParents;

    colorSelectedParents = filter !== null ? productVariant.find((variant) => variant.product.filtrable_color === filter) : null;
    configurableOptions?.forEach((element) => {
      uid.push({
        uid: element.uid,
        label: element.label,
        swatch_data: element.swatch_data?.value,
        haveFilter: colorSelectedParents?.attributes[1].uid === element.uid ? true : false,
      });
    });

    uid.forEach(async (item, index) => {
      if (filter !== null && item.haveFilter) {
        attribute = item.uid;
      } else if (index === 0 && item.haveFilter === null) {
        attribute = item.uid;
      } else if (index === 0) {
        attribute = item.uid;
      }

      const obj = {
        uid: item.uid,
        label: item.label,
        swatch_data: item.swatch_data,
        selected: filter && item.haveFilter ? true : index === 0 ? true : false,
        haveFilter: item.haveFilter,
      };
      configuration.push(obj);
    });

    return [configuration, attribute];
  };

  const getSizeConfiguration = async (productVariant, colorUid: string): Promise<[][]> => {
    let configuration = [];
    let attribute;
    let uid = [];

    if (productVariant && colorUid) {
      productVariant.configurable_options[0].values.forEach((element) => {
        const matchingVariant = productVariant.variants.find(
          (variant) => variant.attributes[0].uid === element.uid && variant.attributes[1].uid === colorUid
        );
        const stockStatus = matchingVariant?.product.only_x_left_in_stock ?? null;

        uid.push({
          uid: element.uid,
          label: element.label,
          swatch_data: element.swatch_data?.value,
          disabled: stockStatus === null,
        });
      });

      uid.forEach((item, index) => {
        const obj = {
          uid: item.uid,
          label: item.label,
          swatch_data: item.swatch_data,
          selected: index === 0,
          disabled: item.disabled,
        };

        if (!attribute && !item.disabled) {
          attribute = item.uid;
          obj.selected = true;
        }

        configuration.push(obj);
      });
    }

    return [configuration, attribute];
  };

  const selectedVariant = async (matchingVariant: any, colorUid, sku: string): Promise<[][]> => {
    const productConfiguration = [matchingVariant.attributes[0].uid, colorUid];
    const result = await getProductDetails({
      filter: {
        sku: {
          eq: sku,
        },
      },
      configurations: Object.entries(productConfiguration).map((config) => config[1]),
    });
    return result.items[0].configurable_product_options_selection.variant;
  };

  const isNewProduct = (product) => {
    const categoriesNew = product?.categories?.find((category) => category.name === 'Nouveautés' || category.name === 'News');
    return categoriesNew ? true : false;
  };

  const getLabelAttribute = async (value) => {
    const attributeService = new AttributesService();
    try {
      const filtrableColor = await attributeService.getFiltrableColor();
      const filtrableArray = Object.values(filtrableColor);
      const deleteAccentMainColor = removeAccents(filtrableArray[0]?.find((option) => option.value === value)?.label);
      const labelColorMain = deleteAccentMainColor.split(' ');
      const wordsLowerCased = labelColorMain.map((word) => word.charAt(0).toLowerCase() + word.slice(1));
      return wordsLowerCased.join('_');
    } catch (e) {
      throw e;
    }
  };

  const setIsOutOfStock = (variants: any[]) => {
    return variants?.every((variant) => variant.product.stock_status === 'OUT_OF_STOCK');
  };

  return {
    getGalleryByColor,
    getSizeConfiguration,
    getColorConfiguration,
    selectedVariant,
    isNewProduct,
    setIsOutOfStock,
  };
}
export default useProductCard;