import { gql } from 'graphql-request';

/**
 * GraphQL Query that fetches products using received search term and the params
 * for filter, sort and pagination.
 */
export default gql`
  query getFacetDataCustom(
    $search: String = ""
    $filter: ProductAttributeFilterInput
    $pageSize: Int = 10
    $currentPage: Int = 1
    $sort: ProductAttributeSortInput
  ) {
    products(
      search: $search
      filter: $filter
      sort: $sort
      pageSize: $pageSize
      currentPage: $currentPage
    ) {
      items {
        sku
        name
        stock_status
        url_key
        url_rewrites {
          url
        }
        uid
        __typename
        categories {
          name
          url_suffix
          url_path
        }
        media_gallery {
          url
          position
          disabled
          label
          __typename
					... on ProductVideo {
						video_content {
							media_type
							video_url
							video_provider
						}
					}
        }
        thumbnail {
          url
          position
          disabled
          label
        }
        price_range {
          maximum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
          minimum_price {
            final_price {
              value
            }
            regular_price {
              value
            }
          }
        }
        url_key
        url_rewrites {
          url
        }
        ... on ConfigurableProduct {
          configurable_options {
            attribute_code
            attribute_uid
            label
            position
            uid
            use_default
            values {
              label
              swatch_data {
                value
              }
              uid
            }
          }
          variants {
            product {
              filtrable_color
              sku
              name
              only_x_left_in_stock
              filtrable_color
              stock_status
              thumbnail {
                url
                label
              }
              media_gallery {
                url
                position
                disabled
                label
                __typename
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_url
                    video_provider
                  }
                }
              }
              price_range {
                maximum_price {
                  final_price {
                    value
                  }
                  regular_price {
                    value
                  }
                }
                minimum_price {
                  final_price {
                    value
                  }
                  regular_price {
                    value
                  }
                }
              }
            }
            attributes {
              code
              label
              uid
              value_index
            }
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;